<template>
  <b-card :title="title">
    <div class="custom-search">
      <!-- advance search input -->
      <b-row v-if="search">
        <b-col md="4">
          <b-form-group>
            <label>ID:</label>
            <b-form-input
              placeholder="Buscar"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Usuario:</label>
            <b-form-input
              placeholder="Buscar"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Tonelada stock:</label>
            <b-form-input
              placeholder="Buscar"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Tonelada contrato:</label>
            <b-form-input
              placeholder="Buscar"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Fecha:</label>
            <b-form-input
              placeholder="Buscar"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Tonelada Blend:</label>
            <b-form-input
              placeholder="Buscar"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: false,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
    >
    <template slot="table-column" slot-scope="props">

     <span v-if="props.column.field =='plan'" style="display: inline-block; width:140px;">
         <download-icon size="1.5x" ></download-icon> {{props.column.label}}
     </span>

     <span v-else-if="props.column.field =='doc'">
         <download-icon size="1.5x" class=""></download-icon>
        {{props.column.label}}
     </span>

     <span v-else-if="props.column.field =='mdemanda'">
         <download-icon size="1.5x" class=""></download-icon>
        {{props.column.label}}
     </span>

     <span v-else>
        {{props.column.label}}
     </span>
  </template>

      <template slot="table-row" slot-scope="props">
        <span
          v-if="props.column.field === 'estado'"
          class="text-success font-weight-bold"
        >
          <span
            v-if="props.row.estado === 'urgente'"
            class="text-danger font-weight-bold"
            >{{ props.row.estado }}</span
          >
          <span
            v-else-if="props.row.estado === 'pendiente'"
            class="pendiente font-weight-bold"
            >{{ props.row.estado }}</span
          >
          <span v-else class="text-success">{{ props.row.estado }}</span>
        </span>

        <span
          v-else-if="props.column.field === 'mortalidad'"
          class="text-nowrap"
        >
          <b-progress
            :value="props.row.mortalidad"
            max="100"
            height="20px"
            striped
            show-progress
            animated
            class="progress"
          />
        </span>
        <span v-else-if="props.column.field === 'plan'" class="text-nowrap">
          <b-button :href="'/descargar_plan_cosecha/' + props.row.planification_id" class="btn historico-planificacion-btn1"
            >Plan Cosecha
          </b-button>
        </span>

        <span v-else-if="props.column.field === 'doc'" class="text-nowrap">
          <b-button :href="'/descargar_plan_productivo/' + props.row.planification_id" class="btn historico-planificacion-btn1"
            >Plan Productivo
          </b-button>
        </span>
        <span v-else-if="props.column.field === 'mdemanda'" class="text-nowrap">
          <b-button :href="'/descargar_match_demanda/' + props.row.planification_id" class="btn historico-planificacion-btn1">Match Demanda </b-button>
        </span>
        <span
          v-else-if="props.column.field === 'dashboard'"
          class="text-nowrap"
        >
          <b-button
            class="btn-primary historico-planificacion-btn2"
            @click="$router.replace({ name: 'dashboard-camanchaca' })"
          >
            Dashboard Global
          </b-button>
        </span>
        <span v-else-if="props.column.field === 'detalle'" class="text-nowrap">
          <b-button
            class="btn-primary btn-standard"
            :to="{ name: 'dashboard-camanchaca-planification-detail', params: { planification_id: props.row.planification_id } }"
            >Ver detalle
          </b-button>
        </span>

        <span
          v-else-if="props.column.field === 'emergency'"
          class="text-nowrap"
        >
          <span v-if="props.row.emergency === 'No'">
            <feather-icon icon="CheckIcon" class="mr-25 text-success" />
            <span class="text-success">{{ props.row.emergency }}</span>
          </span>
          <span v-if="props.row.emergency === 'Si'">
            <feather-icon icon="AlertTriangleIcon" class="mr-25 text-danger" />
            <span class="text-danger">{{ props.row.emergency }}</span>
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'actions'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item>
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Mostrando 1 de </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', pageLengthtable, '40']"
              class="mx-1"
              @input="
                (value) =>
                  props.perPageChanged({
                    currentPerPage: value,
                  })
              "
            />
            <span class="text-nowrap"> de {{ props.total }} entradas </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BProgress,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BBadge,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import {DownloadIcon} from 'vue-feather-icons'

export default {
  props: {
    rows: [],
    search: {
      type: Boolean,
      default: true,
    },
    height: Number,
    datachart: Object,
    title: String,
    pageLengthtable: {
      type: Number,
      default: 20,
    },
    columns: [],
  },
  components: {
    BCard,
    BBadge,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BProgress,
    BRow,
    BCol,
    BDropdownItem,
    BDropdown,
    BButton,
    DownloadIcon,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      pageLength: this.pageLengthtable,
      dir: false,
      searchTerm: "",
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {},
  methods: {
    getCellClass(props) {
      switch (props) {
        case props.row.field === "emergency" && props.row.emergency === "No":
          return `is-green emergency`;
          break;
        case props.row.field === "emergency" && props.row.emergency === "Si":
          return `is-danger emergency`;
          break;
      }
    },
    advanceSearch(val) {
      this.searchTerm = val;
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: "UserIcon",
          variant: "success",
        },
      });
    },
    getDetail(id) {
      window.open(`planning-history/detail/${id}`, "_blank");
    },
    downloadExcel(event) {
      let link = window.document.createElement("a");
      link.href = window.URL.createObjectURL(
        new Blob(["Fake, data"], { type: "text/xls" })
      );
      link.download = event.target.innerText + ".xls";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.detail {
  margin-right: 40% !important;
}
.idplan {
  background-color: #c7b3f2;
}
.emergency {
  width: 100px !important;
  margin-left: 20px;
}
.plan {
  color: #031133 !important;
  font-weight: bold;
}
.dashboard {
  color: #8fc1d4 !important;
  font-weight: bold;
}
.liberado {
  background-color: #8fc1d4 !important;
}
.doc {
  color: #002366 !important;
  font-weight: bold;
}
.pendiente {
  color: #0c102a;
  font-weight: bold;
}
.progress-bar {
  background-color: #00479c;
}
.is-green {
  background: #1ab78d;
  color: white;
}
.is-danger {
  background: #de210c;
  color: white;
}

.historico-planificacion-btn1{
  background-color:#A8A8A8 !important;
  border-color:#A8A8A8 !important;
}

.historico-planificacion-btn2{
  background-color:#777777 !important;
  border-color:#777777 !important;
}
span{
    font-size: 14px;
}
</style>
